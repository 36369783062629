@font-face {
  font-family: AlexBrush;
  src: url('../fonts/AlexBrush-Regular.ttf') format("truetype"); // remember that font file path must be relative to the current css file. not the final compiled folder
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Divers */
.nav-min-height {
  min-height: 71px;
}
.toggler-min-height {
  min-height: 55px;
}
.mt-main {  /* Margin top du main = hauteur header*/
  margin-top: 71px;
}

.h-0 {
  height: 0 !important;
}

.overflow-center {
  margin-left: -100%;
  margin-right: -100%;
}

.mb-6 {
  margin-bottom: 5rem;
}

.mt-25 {
  margin-top: 25vh;
}

.mt-2rem {
  margin-top: 2rem;
}

.z-1000 {
  z-index: 1000;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.text-shadow {
  text-shadow: 2px 2px 2px #212529;
}

.scroll-top {
  display: none;
}

.link-hover-underline {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.posts-links { /* couleur primaire ou secondaire (voire une variante) */
  .page-link {
    color: #b0b0b1; /* couleur générique */
    &:hover {
      color: #375672;
      background-color: #f8f9fa; /* couleur générique */
    }
    &:focus {
      color: #375672;
      background-color: #f8f9fa; /* couleur générique */
      box-shadow: none;
    }
  }
 
  .page-link.active {
    color: #fff;
    background-color: #375672;
    border-color: #375672;
  }
 
  .active {
    >.page-link {
      color: #fff;
      background-color: #375672;
      border-color: #375672;
    }
  }
}

.separator {
  background-color: #cb7680;    /* Voir couleur primaire */
  content: '';
  height: 6px;
  width: 4rem;
}

.text-highlight::before {
  background-color: #f1dadc;
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 40%;
  left: -2px;
  bottom: 0;
  z-index: -1;
}

.main-bg {
  .section-bg {
    &:nth-child(2n+1) {
      background-color: #fff !important;
    }
  }
}

.section-min-height {
  min-height: 620px;
}

/* Navbar */
.logo-size {
  max-height: 45px;
}

/* Carrousel */
.banner-title {
  font-family: Alexbrush;
  font-size: 3rem;
  font-weight: 600;
  line-height: 0.8;
}

.banner-text {
  font-size: calc(0.85rem + 0.5vw);
  line-height: 1.2;
}
 
.banner-btn {
  --bs-btn-hover-color: dimgray;
  --bs-btn-active-color: dimgray;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1920px;
  height: 100%;
  margin-left: -960px;
  background-color: #212529;
  opacity: 0.3;
}

.img-carousel-mobile  {
  height: 350px !important;
  width: auto !important;
}

/* Section Présentation */
.about-img-shadow {
  box-shadow: -1.5rem 1.5rem 0 #f1dadc;
}

[data-aos="about-img-animation"] {
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
  }
}

.intro-height {
  min-height: 100px; 
}
  
/* Section agrément */
.approval-logo-size {
  max-height: 120px;
}

/* Section Valeurs */
.value-image-shadow {
  box-shadow: -0.5rem 0.5rem 0 #f1dadc;
}

.value-image {
  max-width: 276px;
  margin: auto;
}

.value-description {
  padding: 0 1rem;
}

/* Section Avis */
.owl-dots {
  text-align: center;
}

.section-bg {
  .owl-carousel {
    button.owl-dot {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      margin: 0 5px;
      background: #fff;
      &.active {
        background: #375672 !important;
      }
    }
  }
}

.main-bg {
  .section-bg {
    &:nth-child(2n+1) {
      .owl-carousel {
        button.owl-dot {
          background: #efefef;
        }
      }
    }
  }
}

.review-link {
  margin-right: 3rem;
}

.testimonial {
  margin: 0 16px 40px;
}

.testimonial .testimonial-content {
  padding: 30px 25px;
  margin-bottom: 35px;
  background: #fff;
  border: 1px solid #f0f0f0;
  height: 275px;
}

.testimonial .testimonial-description {
  font-style: italic;
}

.testimonial .testimonial-description p {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0.25em 0;
  padding: 5px 20px 5px 48px;
  line-height: 1.45;
}

.testimonial .testimonial-description p:before {
  content: "\201c";
  display: block;
  padding-left: 10px;
  font-family: "Georgia";
  font-size: 90px;
  color: #f1dadc;
  position: absolute;
  top: -30px;
  left: -18px;
}

.testimonial .testimonial-stars {
  text-align: center;
}

.testimonial .testimonial-name {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.google-stars {
  font-size: 1.2rem;
  color: #FFCD03;
}

.trustpilot-stars {
  font-size: 1.2rem;
  color: #21A56E;
}

.align-stars {
  .google-stars {
    vertical-align: middle !important;
  }

  .trustpilot-stars {
    vertical-align: middle !important;
  }
}

.review {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.position-stars {
  position: absolute;
  top: 33px;
  left: 15px;
}

/* Section Blog */
.btn.btn-filter {
  color: #cb7680;
  background-color: #fff;
  border-color: #cb7680;
  &:hover {
      color: #cb7680;
      border-color: #cb7680;
  }
  &:focus {
      color: #cb7680;
      border-color: #cb7680;
  }
  &:active {
      color: #cb7680;
      border-color: #cb7680;
  }
}
.btn.btn-filter.active {
  color: #fff;
  background-color: #cb7680;
  border-color: #cb7680;
}
[data-aos="post-comment-animation"] {
  opacity: 0;
  transform: translateY(100px);
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.post-comment {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.post-comment.promoted {
  -webkit-line-clamp: 6;
}

.post-view {
  overflow: hidden;
  position: relative;
  img {
    transition: all 0.2s linear;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.post {
  a {
   color: #cb7680;
   text-decoration: none;
    &:hover {
      color:#d99ba2
    }
  }
 
  img[style="float: left;"] {
    margin-right: 1rem;
  }
 
  img[style="float: right;"] {
    margin-left: 1rem;
  }
 
  img {
    max-width: 100%;
    height: auto;
    margin-top: 1rem; margin-bottom: 0.5rem;
    cursor: pointer;
  }
}

.social-buttons {
	ul {
		--bs-nav-link-padding-x: 1rem;
		--bs-nav-link-padding-y: 0.5rem;
		--bs-nav-link-font-weight: ;
		--bs-nav-link-color: var(--bs-link-color);
		--bs-nav-link-hover-color: var(--bs-link-hover-color);
		--bs-nav-link-disabled-color: var(--bs-secondary-color);
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
}

.social-button {
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.facebook-button {
  background-image: url('../images/social-media/facebook.webp');
}
 
.twitter-button {
  background-image: url('../images/social-media/x-twitter.webp');
}
 
.linkedin-button {
  background-image: url('../images/social-media/linkedin.webp');
}

/* Section Services */
.card {
  box-shadow: 2px 6px 8px 0 #16161a2e;
  border: none;
}

.service-content {
  width: 296px;
  margin: auto;
}

.service-infos {
  position: absolute;
  left: 0;
  right: 0;
  height: 62px;
  transition: all 0.5s ease-in-out;
  background-color: #37567299; /* Couleur primaire à 60% */
  opacity: 1;
}
 
.service-text {
  font-size: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  & p:last-child {
    margin-bottom: 0;
  }
}
 
.service-title {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  font-size: 1.25rem;
}

.owl-prev, .owl-next{
  background-color: #343a40 !important;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.8;
  position: absolute;
}

.owl-prev{
  left: 0px;
}

.owl-next{
  right: 0px;
}

.owl-prev span, .owl-next span {
  position: absolute;
  top: -20%;
  color: white;
  font-size: 2.5rem;
}

.owl-prev span{
  left: 35%;
}

.owl-next span{
  right: 35%;
}
   
/* Section Réalisations */
.project-view {
  overflow: hidden;
  position: relative;
  img {
    transition: all 0.2s linear;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.project-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #375672;
  opacity: 0;
  transition: all 0.3s linear;
  &:hover {
    opacity: 0.7;
  }
}
  
/* Section Contact */
#message {
  max-height: 296px;
}

.fs-24 {
  font-size: 1.5rem;
}

.contact-block::after {
  position: absolute;
  background-color: #dddddd;
  content: "";
  bottom: 0;
  left: 12.5%;
  height: 1px;
  width: 75%;
}
 
.contact-block:last-child::after {
  background-color: transparent !important;
}

.legend-color {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  background-color: #3388ff4d;
  border: 1px solid #3388ff99;
  border-radius: 1rem;
}

#map {
  height: 500px;
}

.legend {
  background-color: #ffffffbf;
  position: absolute;
  z-index: 800;
  border: 2px solid #0000004d;
  border-radius: 4px;
  bottom: 20px;
  left: 10px;
}

.leaflet-touch .leaflet-bar a {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.form-control {
  &.is-invalid, &.is-valid{
    background-image: none;
  }
}

.form-check-input.is-valid {
  border-color: #00000040;
}

.form-check-input.form-check-color:checked {  /* Couleur primaire ou secondaire selon la couleur des labels */
  background-color: #cb7680;
  border-color: #cb7680;
}

.form-check-input.form-check-color:focus {   /* Couleur primaire ou secondaire selon le cas */
  border-color: #cb7680;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #cb768040;  /* opacité plus faible */
}

.btn-call-link {
  width: 48px;
  transition: width 0.3s;
  padding: 6px 11px;
  border-width: 3px;
  border-radius: 0.375rem;
  background-color: #cb7680;
  border-color: #cb7680;
  color: #fff;
  &.active{
    background-color: #cb7680;
    border-color: #cb7680;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #cb7680 !important; /* luminosité HSL diminuée de 10% */
    border-color: #cb7680 !important; /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
  .phone-number {
    opacity: 0;
  }
}

.agenda-item {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0 0 1px rgba(0,0,0,.12);
  &.booked {
    background-color: rgba(203, 118, 128, 0.07) !important;
    box-shadow: rgba(203, 118, 128, 0.8) 0px 0px 0px 1px !important;
    color: #cb7680 !important;
    opacity: 0.65;
  }
  &.closed {
    background-color: transparent !important;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.4) !important;
    opacity: 0.65;
    color: #6c757d !important;
  }
}

/* Footer */
/* Couleur footer générique (si couleurs primaires ou secondaires ne conviennent pas */
.bg-footer {  /* main-footer */
  background-color: #343a40;
}

.bg-footer-dark {  /* sub-footer */
  background-color: #1e2124;
}

.footer-link {  /* Liens réseaux sociaux */
  color: white;
  text-decoration: none;
  &:hover {
    color: #e1e1e1;
  }
}

#sub-footer a:hover {
  font-weight: 600;
}

/* COULEURS à éditer puis classes à éventuellement modifier dans les vues */
  /* Couleur primaire - 1 couleur du logo, ici #375672 - Peut servir de couleur sombre de titre/texte si assez foncée sinon voir couleur tertiaire */

.text-color-primary {
  color:#375672;
}

.bg-color-primary {
  background-color: #375672 !important;
}

.border-color-primary {
  border-color: #375672 !important;
}

.bg-color-primary-light {
  background-color: #f5f8fa !important;  /* Luminosité augmenté à 85-90% */
}

.bg-color-primary-dark {
  background-color: #263c4f !important;  /* Luminosité diminué de 10-15% */
}

.btn-color-primary {
  background-color: #375672;
  border-color: #375672;
  color: #fff;
  &.active{
    background-color: #375672;
    border-color: #375672;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #263c4f !important; /* luminosité HSL diminuée de 10% */
    border-color: #263c4f !important; /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.btn-color-primary-outline {
  background-color: transparent;
  border-color: #375672;
  color: #375672;
  &:hover, &:focus, &:active {
    background-color: #375672 !important;
    border-color: #375672 !important;
    color: #fff !important;
  }
}

.link-color-primary {
  color:#375672 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    font-weight: 600;
  }
}

.nav-link.text-color-primary {  /* Alternative "couleur primaire" de titre si besoin */
  color: #375672 !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
    &:hover, &.active {
      color: #cb7680 !important;  /* Couleur secondaire au survol */
    }
}

.form-color-primary {
  background-color: #3756720d !important;
  &:focus {
    color: #263c4f;
    border-color: #37567280;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #37567240;
  }
}

/* Couleur secondaire - Ici, 2ème couleur du logo : #cb7680 + variantes avec luminosité HSL diminuée de 10% ou opacité plus faible
(Peut nous servir également de couleur par défaut pour titres/link/footer si assez sombre)
    OU couleur d'accentuation choisie par client voire nous
*/

.text-color-secondary {
  color:#cb7680;
}

.bg-color-secondary {
  background-color: #cb7680 !important;
}

.border-color-secondary {
  border-color: #cb7680 !important;
}

.bg-color-secondary-light {
  background-color: #f1dadc !important;  /* Luminosité augmenté à 85-90% */
}

.bg-color-secondary-dark {  /* Par exemple, subfooter */
  background-color: #bd515e;  /* luminosité HSL diminuée de 10% */
}

.btn-color-secondary {
  background-color: #cb7680;
  border-color: #cb7680;
  color: #fff;
  &.active{
    background-color: #cb7680;
    border-color: #cb7680;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #d99ba2 !important;  /* luminosité HSL diminuée de 10% */
    border-color: #d99ba2 !important;  /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.btn-color-secondary-outline-light {
  background-color: transparent;
  border-color: #f8edee;
  color: #f8edee;
  &:hover, &:focus, &:active {
    background-color: #f8edee !important;
    border-color: #f8edee !important;
    color: #375672 !important;  /* Ajuster selon la couleur du bg */
  }
}

.btn-color-secondary-outline {
  background-color: transparent;
  border-color: #cb7680;
  color: #cb7680;
  &:hover, &:focus, &:active {
    background-color: #cb7680 !important;
    border-color: #cb7680 !important;
    color: #fff !important;  /* Ajuster selon la couleur du bg */
  }
}

.form-color-secondary {
  background-color: #cb76800d !important;  /* opacité plus faible */
  &:focus {
    color: #263c4f;
    border-color: #cd768080;  /* opacité plus faible */
    outline: 0;
    box-shadow: 0 0 0 0.2rem #cb768040;  /* opacité plus faible */
  }
}

.nav-link.text-color-secondary {  /* 1ère alternative de "noir" de titre si la couleur secondaire est un gris foncé */
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, font-weight .3s ease-in-out;
  &.inactive {
    color: #343a40 !important;
    font-weight: normal;
  }
  &:hover, &.active {
    color: #9c2c2c !important;  /* Couleur primaire au survol */
    font-weight: 600;
  }
  &::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.link-color-secondary {
  color:#cb7680 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    color:#d99ba2 !important;
  }
}

/* Couleur tertiaire = éventuelle 3ème couleur différente ou basée par exemple sur la couleur primaire si celle-ci pas assez foncée */
.text-color-tertiary {
  color:#263c4f;   /* Exemple ici : secondary à 5-10% de luminosité */
}

.bg-color-tertiary {
  background-color: #263c4f;
}

.btn-color-tertiary {
  background-color: #631c1c;
  border-color: #631c1c;
  color: #fff;
  &.disabled {
    background-color: transparent;
    color: #631c1c;
  }
  &.active{
    background-color: #631c1c;
    border-color: #631c1c;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #3c1111 !important; /* luminosité HSL diminuée de 10% */
    border-color: #3c1111 !important;  /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.nav-link.text-color-tertiary {
  color: #263c4f !important;
  font-weight: normal;
  &:hover, &.active {
    color: #375672 !important;  /* Couleur primaire ou secondaire au survol */
    font-weight: 600;
  }
  &::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.link-color-tertiary {
  color:#263c4f !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #375672 !important;  /* Couleur primaire ou secondaire au survol */
    font-weight: 600;
  }
}

/* Media queries */
@media (min-width: 360px) {
  .service-content {
    width: 320px;
  }
}
  
@media (min-width: 400px) {
  .value-description {
    padding: 0 1.5rem;
  }

  .service-text {
    font-size: 16px;
  }
}

@media (min-width: 472px) {
  .agenda-form {
    max-width: 448px;
  }
}

@media (min-width: 500px) {
  .service-content {
    width: 420px;
  }

  .service-text {
    font-size: 18px;
  }

  .value-description {
    padding: 0 2.5rem;
  }
}
  
@media(min-width: 576px) {
  .banner-title {
    font-size: 4rem;
  }

  .review-link-footer {
    margin-right: 3rem;
  }

  .service-content {
    width: auto;
  }

  .service-text {
    font-size: 15px;
  }
}

@media(max-width: 767px)  {
  .post {
    img {
      float: none !important;
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
  
@media(min-width: 768px)  {
  .img-carousel-mobile  {
    height: 440px !important;
  }

  .contact-block::after {
    background-color: transparent !important;
  }
 
  .contact-odd:nth-child(even)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }

  .legend {
    bottom: 10px;
  }

  .logo-size {
      max-height: 50px;
  }

  .nav-min-height { 
    min-height: 76px;
  }

  .toggler-min-height { 
    min-height: 60px;
  }

  .mt-main { 
    margin-top: 76px;
  }

  .service-text {
    font-size: 16px;
  }

  .modal-img {
    max-width: 75%;
  }
}
  
@media (max-width: 991px) {
  .aos-img {
    opacity: 1 ; 
    transform : none;
    margin-bottom: 1rem; 
  }
}
  
@media(min-width: 992px) {
  .banner-title {
    font-size: 5rem;
  }

  .img-carousel-mobile  {
    height: auto !important;
    max-width: 100% !important;
  }

  .about-img-shadow {
    box-shadow: -2.5rem 2.5rem 0 #f1dadc;
  }

  .review-link {
    margin-right: 32px;
  }

  [data-aos="post-comment-animation"] {
    opacity: 0;
    transform: translateX(100px);
    transition-property: transform, opacity;
    &.aos-animate {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .post-comment.promoted {
    -webkit-line-clamp: 5;
  }

  #map {
    height: auto;
  }

  .agenda-grid {
    grid-template-columns: repeat(auto-fill, 108px);
  }
  
  .agenda-item {
    height: 108px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .contact-even:nth-child(even)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mb-lg_xl-10 {
    margin-bottom: 10rem !important;
  }
} 
  
@media (max-width: 1199px) {
  #banner-img {
    max-width: none;
    width: auto;
    height: 300px;
  }
}
  
@media(min-width: 1200px)  {
  .banner-info {
    width: 1200px;
  }

  .banner-title {
    font-size: 5.5rem;
  }

  .banner-text {
    font-size: calc(1rem + 0.5vw);
    line-height: 1.2;
  }

  .banner-btn{
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-border-width: 2px;
  }

  .service-content:hover {
    & .service-infos {
        height: 100%;
    }
 
    & .service-text {
      transition: all 0.5s ease-in-out;
      opacity: 1;
    }
 
    & .service-title {
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }
  }

  .logo-size {
      max-height: 55px;
  }

  .nav-min-height {
    min-height: 81px;
  }

  .mt-main {  /* Margin top du main = hauteur header*/
    margin-top: 81px;
  }

  .modal-img {
    max-width: 45%;
  }

  .post-comment.promoted {
    -webkit-line-clamp: 6;
  }
  
  .contact-even:nth-child(2)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
 
  .contact-even:nth-child(2n+3)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
  
  .btn-call-link:hover {
    width: 100%;
    transition: width 1s;
    .phone-number {
      opacity: 1;
    }
  }
}
      
@media (min-width: 1400px) {
  .banner-title {
    font-size: 6rem;
  }

  .service-text {
    font-size: 18px;
  }

  .service-title {
    transition: all 0.5s ease-in-out;
    opacity: 1;
    font-size: 1.5rem;
  }
}